<template>
  <div class="index">
    <div class="box set-box">
      <div class="title">
        业绩目标设置
        <el-button  type="primary" size="mini" @click="$router.go(-1)">
          返回
        </el-button>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in targetAddList" :key="index">
          <div class="left">
            <div class="left-top">
              <p>
                <label>姓 名</label>
                <el-input
                  class="my-input"
                  placeholder=""
                  size="mini"
                  v-model="item.user_name"
                ></el-input>
              </p>
              <p>
                <label>选择省份</label>
                <el-select
                  class="my-input"
                  v-model="item.provinceList"
                  @change="item.province = item.provinceList.join(',')"
                  multiple
                  size="mini"
                  placeholder=""
                >
                  <el-option
                    v-for="item2 in option_sf"
                    :key="item2.value"
                    :label="item2.label"
                    :value="item2.label"
                  ></el-option>
                </el-select>
                <!-- <el-input
                  class="my-input"
                  placeholder=""
                  id="name"
                  size="mini"
                  v-model="screenData.name">
                </el-input> -->
              </p>
            </div>
            <div class="proviceText-box">
              <span>已选省份: </span>
              <p>{{ item.province }}</p>

            </div>
          </div>
          <div class="line"></div>
          <div class="content">
            <div>
              <p class="years-picker-box">
                <label>选择年份</label>
                <el-date-picker
                  class="my-input"
                  size="mini"
                  value-format="yyyy"
                  v-model="item.year"
                  type="year"
                  placeholder="选择年"
                ></el-date-picker>
              </p>
              <p v-for="(item2, index2) in 6" :key="index2">
                <label>{{ item2 }}月份</label>
                <el-input
                  class="my-input"
                  placeholder=""
                  id="name"
                  size="mini"
                  type="Number"
                  v-model="item['month_0' + item2]"
                ></el-input>
              </p>
            </div>
            <div>
              <p>
                <label>年度业绩</label>
                <el-input
                  class="my-input"
                  placeholder=""
                  id="name"
                  :disabled="true"
                  size="mini"
                  :value="annualTarget(item)"
                ></el-input>
              </p>
              <p v-for="(item2, index2) in 6" :key="index2">
                <label>{{ item2 + 6 }}月份</label>
                <el-input
                  class="my-input"
                  placeholder=""
                  id="name"
                  size="mini"
                  type="Number"
                  v-model="
                    item[
                      'month_' + (item2 + 6 > 9 ? item2 + 6 : '0' + (item2 + 6))
                    ]
                  "
                ></el-input>
              </p>
            </div>
          </div>
          <div class="right">
            <!-- <span v-if="targetAddList.length>1" @click="delListItem(index)">删除</span>
             -->
            <div v-if="targetAddList.length>1">
              <el-popconfirm
                title="该条内已有数据，确定删除吗？"
                @confirm="delListItem(index)"
                v-if="popDelShow(index)"
              >
                <span slot="reference" v-if="targetAddList.length>1" >删除</span>
              </el-popconfirm>
              <span  v-else @click="delListItem(index)">删除</span>
            </div>
          </div>
        </div>
      </div>
      <div class="add-box" v-if="isAdd">
        <el-button  size="mini" @click="addListItem" class="lang-button">
          新 增 一 行
        </el-button>
      </div>
      <div class="save-box">
        <el-button type="primary" size="mini" @click="isAdd?add_sale_target_setting():put_sale_target_setting()">
          保 存
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/other.js'
import { Message } from 'element-ui'
export default {
  data() {
    return {
      isAdd: true,
      option_sf: [],
      targetAddList: [
        {
          user_name: '',
          provinceList: [],
          province: '',
          year: '',
          month_01: '',
          month_02: '',
          month_03: '',
          month_04: '',
          month_05: '',
          month_06: '',
          month_07: '',
          month_08: '',
          month_09: '',
          month_10: '',
          month_11: '',
          month_12: '',
        },
      ],
    }
  },
  created() {
    let query = this.$route.query
    if (query.isEdit) {
      this.isAdd = false
      this.targetAddList = [JSON.parse(query.targetitem)]
      this.targetAddList[0].provinceList =
      this.targetAddList[0].province.split(',')
      this.targetAddList[0].year = '' + this.targetAddList[0].year
    }
    this.option_sf = JSON.parse(localStorage.getItem('province'))
  },
  computed: {
    annualTarget() {
      return (item) => {
        let sum = 0
        let i = 1
        while (i <= 12) {
          let temp = item['month_' + (i < 10 ? '0' + i : i)]
          sum += Number(temp)
          i++
        }
        return sum
      }
    },
    // 显示什么样的删除
    popDelShow() {
      return (index)=>  {
        for(let key in this.targetAddList[index]){
          if(this.targetAddList[index][key]&&this.targetAddList[index][key]!=false){
            return true
          }
        }
        return false
      }
    }
  },
  methods: {
    // 新增一行
    addListItem() {
      this.targetAddList.push({
        user_name: null,
        provinceList: [],
        province: '',
        year: '',
        month_01: '',
        month_02: '',
        month_03: '',
        month_04: '',
        month_05: '',
        month_06: '',
        month_07: '',
        month_08: '',
        month_09: '',
        month_10: '',
        month_11: '',
        month_12: '',
      })
    },
    // 删除一行
    delListItem(index) {
      // for(let key in this.targetAddList[index]){
      //   if(this.targetAddList[index][key]&&this.targetAddList[index][key]!=false){
      //     console.log(this.targetAddList[index][key])
      //     break;
      //   }
      // }
      this.targetAddList.splice(index,1)
    },
    // 新增业绩目标
    add_sale_target_setting() {
      // this.targetAddList.forEach(e => {
      //   e.user_name = e.user_name?e.user_name:null
      // })
      let params = []
      for(let i=0;i<this.targetAddList.length;i++){
        let temp = this.check_params({...this.targetAddList[i]})
        if(temp){
          params.push(temp)
        }else{
          Message.warning('请填写省份、年份和至少一个月份的业绩目标')
          return
        }
      }
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

      api.post_sale_target_setting(params).then((res) => {
        if(res.code == 200){
          loading.close();
          this.$alert('新增成功', {
            confirmButtonText: '确定',
            callback: action => {
              loading.close();
              this.$router.go(-1)
            }
          })
        }else{
          loading.close();
        }
      }).catch(err => {
        loading.close();
      })
    },
    // 修改业绩目标
    put_sale_target_setting() {
      let item = this.targetAddList[0]
      let data = {...item}
      delete data.id
      data = this.check_params(data)
      if(!data){
        Message.warning('请填写省份、年份和至少一个月份的业绩目标')
        return
      }
      let params = {
        id: item.id,
        data,
      };
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      api.put_sale_target_setting(params).then(res=> {
        if(res.code == 200){
          loading.close();
          this.$alert('修改成功', {
            confirmButtonText: '确定',
            callback: action => {
              loading.close();
              this.$router.go(-1)
            }
          })
        }else{
          loading.close();
        }
      }).catch(err => {
        loading.close();
      })
    },
    // 发送前校验
    check_params(item) {
      if(item.province!=''&&item.year!=''){
        for(let i in item){
          if(item[i]==''){
            delete item[i]
          }
        }
        if(item.user_name){
          return Object.keys(item).length>4?item:false
        }else {
          return Object.keys(item).length>3?item:false
        } 
      }else {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$gap: px(10);
.index {
  padding: $gap;
}
.box {
  background-color: #fff;
  padding: $gap;
  margin: $gap;
  border-radius: $gap/2;
  border: px(1) #eee solid;
}
.set-box {
  .item {
    display: flex;
    border-bottom: px(1) #eee solid;
    padding: $gap;
    .left {
      flex: 2;
      .left-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        p {
          text-align: center;
          label {
            color: #037EB4; 
            padding: $gap/2 0;
            display: inline-block;
          }
          ::v-deep .my-input {
            .el-input__inner {
              width: 97%;
            }
          }
          ::v-deep .el-select__tags-text {
            color: #606266;
          }
        }
      }
      .proviceText-box {
        padding: $gap/2 0;
        &>span {
          color: #999;
        }
        &>p {

        }
      }
    }
    .line {
      width: 1px;
      background-color: #eee;
      margin: $gap;
    }
    .content {
      flex: 7;
      & > div {
        display: flex;
        justify-content: space-between;
      }
      p {
        width: 14%;
        text-align: center;
        label {
          color: #037EB4; 
          padding: $gap/2 0;
          display: inline-block;
        }
        ::v-deep .my-input {
          .el-input__inner {
            width: 100%;
          }
        }
      }
      
      .years-picker-box {
        ::v-deep .el-date-editor.el-input {
          width: 100%;
        }
      }
    }
    .right {
      width: px(100);
      display: flex;
      justify-content: center;
      align-items: center;
      color: red;
      span {
        cursor: pointer;
      }
      span:hover {
        text-decoration: underline;
      }
    }
  }
  .add-box {
    padding: $gap;
    .lang-button {
      width: 15%;
    }
  }
  .save-box {
    padding: $gap;
    text-align: center;
  }
}
.title {
  font-size: px(20);
  font-weight: bold;
  border-bottom: px(1) solid #eee;
  padding-bottom:  $gap;
  display: flex;
  justify-content: space-between;
}
.notify-btn {
  padding: $gap/4 $gap;
  border-radius: 5px;
  border: 0;
  color: #fff;
  background-color: #409EFF;
}
.notify-btn:hover {
  background-color: #53A8FF;
}

</style>